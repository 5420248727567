<!-- 用户中心 -->
<template>
  <div class="usercenter-box">
    <!-- <div class="usercenter-left">
      <ul>
        <li v-for="item in leftNavList" :key="item.id" @click="changePage(item.id)">
          <img :src="!item.active ? item.defaultImg : item.activeImg" alt="">
          <div :class="{'active-font': item.active}">{{ item.name }}</div>
        </li>
      </ul>
    </div> -->
    <div class="usercenter-right">
      <div class="breadcrumb">
        <!-- separator-class="el-icon-arrow-right" -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="item in leftNavList"
            :key="item.id"
            :to="{ name: 'UserCenter', query: { type: item.id } }"
          >
            <span
              :class="{ 'active-font': item.active }"
              @click="changeType(item.id)"
              >{{ item.name }}</span
            >
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <component
        :is="dynamicComponent"
        @linkOtherPage="linkOtherPage"
        @isDetail="isDetail"
        :ref="dynamicComponent"
        :isView="isDetailShow"
        :orderDetailData="orderDetailData"
        @updateIsView="updateIsView"
      >
      </component>
    </div>
  </div>
</template>
<script>
import UserInfo from './modules/UserInfo'
import MyOrder from './modules/MyOrder'
import OrderDetails from './modules/OrderDetails.vue'

export default {
  data() {
    return {
      isDetailShow: false,
      leftNavList: [],
      orderDetailData: [],
      tempNavlist: [
        {
          id: '1',
          name: '个人中心',
          defaultImg: require('../assets/imgs/usercenter/user-icon.png'),
          tempImg: require('../assets/imgs/usercenter/user-icon.png'),
          activeImg: require('../assets/imgs/usercenter/user-active.png'),
          active: false,
          componentName: 'UserInfo',
        },
        {
          id: '2',
          name: '我的订单',
          defaultImg: require('../assets/imgs/usercenter/order-icon.png'),
          tempImg: require('../assets/imgs/usercenter/order-icon.png'),
          activeImg: require('../assets/imgs/usercenter/order-active.png'),
          active: false,
          componentName: 'MyOrder',
        },
      ],
      dynamicComponent: 'UserInfo',
      tabPosition: 'left',
      activeTab: 'renewRecord',
    }
  },
  components: {
    UserInfo,
    MyOrder,
    OrderDetails,
  },
  // 通过路由获取商品id
  activated() {},
  watch: {
    $route: {
      immediate: true,
      handler() {
        const type = this.$route.query.type
        // if (window.innerWidth > 1024) {
        this.changePage(type)
        // }
      },
    },
  },
  methods: {
    updateIsView(val) {
      this.isView = val
    },
    changeType(id) {
      console.log('change', id)
      if (id === '2' && this.isDetailShow) {
        this.changePage('2')
        this.isDetailShow = false
        this.$refs[this.dynamicComponent].isView = false
      }
    },
    isDetail(val, row) {
      console.log(val, row)
      this.isDetailShow = true
      this.orderDetailData = row
      localStorage.setItem('orderId', row.orderId)
      this.changePage('3')
    },
    changePage(id) {
      console.log(id)
      if (id === '1') {
        this.leftNavList = this.tempNavlist.filter(
          (item) => item.id === this.$route.query.type
        )
      } else if (id === '3') {
        console.log()
        this.leftNavList = this.tempNavlist.filter((item) => item.id !== '1')
        if (!this.leftNavList.some((item) => item.name === '订单详情')) {
          this.leftNavList.push({
            id: '3',
            name: '订单详情',
            defaultImg: require('../assets/imgs/usercenter/order-icon.png'),
            tempImg: require('../assets/imgs/usercenter/order-icon.png'),
            activeImg: require('../assets/imgs/usercenter/order-active.png'),
            active: false,
            componentName: 'OrderDetails',
          })
        }
      } else {
        this.leftNavList = this.tempNavlist.filter((item) => item.id !== '1')
      }
      this.leftNavList.map((item) => {
        if (item.id === id) {
          item.active = true
          if (id === '3') {
            console.log('id====333333', this.$route.query)
            this.$router.push({
              name: 'UserCenter',
              query: { type: id, orderId: this.$route.query.orderId },
            })
          } else {
            this.$router.push({ name: 'UserCenter', query: { type: id } })
          }
          this.dynamicComponent = item.componentName
        } else {
          item.active = false
        }
        return item
      })
    },
    linkOtherPage(val, id) {
      // this.dynamicComponent = val
      console.log('val', val)
      this.$router.push({ name: val, query: { type: id, name: val } })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-breadcrumb__separator {
  font-size: 1rem !important;
}
::v-deep .el-breadcrumb__inner.is-link {
  color: #999 !important;
  font-size: 1rem !important;
}
.breadcrumb {
  padding: 20px;
  padding-left: 50px;
}
.breadcrumb span:hover {
  cursor: pointer;
}

.el-breadcrumb {
  font-size: 24px !important;
}

.el-breadcrumb-item img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
.el-breadcrumb-item span {
  vertical-align: middle;
}
.active-font {
  color: #fa8919;
}
.usercenter-box {
  width: 90%;
  height: 68vh;
  margin: 0 auto;
  min-height: 68vh;
  overflow-y: auto;
  margin: 40px auto 15px;
  display: flex;
  .usercenter-left {
    // width: 20%;
    width: 14%;
    height: calc(68vh - 40px);
    background-color: #fff;
    padding-top: 40px;
    ul {
      width: 70%;
      margin: 0 auto;
      border-top: 1px solid #ddd;
      box-sizing: border-box;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 20px;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        cursor: pointer;
        div {
          // font-size: 1.2rem;
          font-size: 1rem;
        }
      }
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
    }
  }
  .usercenter-right {
    width: calc(98% - 30px);
    // width: calc(87% - 30px);
    margin-left: 30px;
    // min-height: 68vh;
    background-color: #fff;
    // padding: 40px;
  }
}
// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .usercenter-box {
    width: 93%;
    margin-top: 20px;
    .usercenter-right {
      width: 100%;
      margin-left: 0;
      height: max-content;
    }
  }
  .usercenter-left {
    display: none;
  }
  .page-left-box {
    padding: 0;
  }

  .breadcrumb {
    display: none;
  }
}
</style>
