<!--
* @Description: 用户中心
* @Author: xxxxx
* @Date: 2020-3-27 16:18:25
* @LastEditors: xxxxx
* @LastEditTime: 2020-3-27 16:18:25
-->
<template>
  <div class="page-left-box">
    <div class="back-btn">
      <img src="@/assets/imgs/back-btn.png" alt="" @click="$router.back()" />
      <span class="user-tit">个人中心</span>
    </div>
    <div class="userInfo-box">
      <div class="cur-user-info">
        <img :src="userInfo.avatar || defaultImg" alt="" class="user-logo" />
        <div class="user-txt-box">
          <p>
            <span class="user-txt-style"> 企业名称：</span
            ><span :title="userInfo.brandName">
              <!-- .slice(0, 15)  -->
              {{ userInfo.brandName }}</span
            >
          </p>
          <p
            @click="copyText"
            :title="userInfo.mobile"
            style="color: #fa8919; cursor: pointer"
          >
            <span class="user-txt-style">手机账号：</span>{{ userInfo.mobile }}
          </p>
          <!-- <p><span @click="gotoOfficialWebsite"  class="user-txt-style">企业域名：</span>{{ 'http://'+userInfo.user.domainName }}</p> -->
          <p
            :title="'http://' + userInfo.domainName"
            @click="gotoOfficialWebsite"
            style="cursor: pointer"
          >
            <span class="user-txt-style">公司域名： </span>
            <span style="color: #fa8919; cursor: pointer"
              >{{ 'http://' + userInfo.domainName }}
            </span>
          </p>
          <p>
            <span class="user-txt-style">创建时间：</span
            >{{ userInfo.createTime | handleEndTime }}
          </p>
          <p>
            <span class="user-txt-style">到期时间：</span
            >{{ userInfo.expirationTime | handleEndTime }}
          </p>
          <p>
            <span class="user-txt-style">剩余时长：</span
            >{{ userInfo.remainingDuration }}
          </p>
        </div>
      </div>
      <div class="h5-opt-box">
        <div class="h5-inner-box">
          <el-button size="small" @click="goOrder">我的订单</el-button>
        </div>
      </div>
      <div class="user-opt-type">
        <el-button size="small" @click="goForget">修改密码</el-button>
        <el-button size="small" @click="logout">退出登录</el-button>
      </div>
    </div>
    <MyforgetPassword
      :forgetPassword="forgetPassword"
      @fromChild="isForgetPassword"
    ></MyforgetPassword>
  </div>
</template>
<script>
import { updateUserInfo } from '@/api/home'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import { getwxShare } from '@/utils/wexinShare'
export default {
  data() {
    return {
      orders: [],
      msg: '',
      ordersRenew: [],
      forgetPassword: false,
      defaultImg: require('../../assets/imgs/home/logoPerson.png'),
    }
  },
  filters: {
    handleEndTime(endDate) {
      if (!endDate) {
        return
      }
      return endDate.substring(0, 10)
    },
    handleTime(endDate) {
      if (!endDate) {
        return
      }
      // 计算相隔天数
      const start = dayjs(dayjs().format('YYYY-MM-DD'))
      const end = dayjs(endDate)
      const diffInDays = end.diff(start, 'day')

      // 转换为「n年n月n天」格式
      const years = Math.floor(diffInDays / 365)
      const months = Math.floor((diffInDays % 365) / 30)
      const days = diffInDays % 30
      return `${years}年${months}月${days}天`
    },
  },
  computed: {
    userInfo: {
      get() {
        return this.$store.state.user.user
      },
      set(value) {
        this.$store.commit('setUser', value)
      },
    },
  },
  watch: {
    // 通过订单信息，计算出每个订单的商品数量及总价
    orders: function (val) {
      let total = []
      for (let i = 0; i < val.length; i++) {
        const element = val[i]

        let totalNum = 0
        let totalPrice = 0
        for (let j = 0; j < element.length; j++) {
          const temp = element[j]
          totalNum += temp.product_num
          totalPrice += temp.product_price * temp.product_num
        }
        total.push({ totalNum, totalPrice })
      }
      this.total = total
    },
  },
  created() {
    this.updateUserInfo()
  },
  mounted(){
    this.initWeChatConfig()
  },
  methods: {
    ...mapActions([
      'setUser',
      'setShowLogin',
      'setShoppingCart',
      'setShowForgetPassword',
    ]),
    async initWeChatConfig() {
      var url = window.location.href.split('#')[0].split('?')[0];
      var goodsType = 3
      var params={
        url:url,
        goodsId:3,
        goodsType:goodsType
      }
    const path ='#/home';
     getwxShare(params,url,goodsType,path,'个人中心')
    },
    async updateUserInfo() {
      const phone = this.userInfo.mobile
      const res = await updateUserInfo(phone)
      if (res.code == 0) {
        const userInfo = res.data.loginUser
        this.userInfo = userInfo
      }
    },
    // 点击域名跳转至官网
    gotoOfficialWebsite() {
      // 执行跳转操作
      window.open(`http://${this.userInfo.domainName}`, '_blank')
    },
    // 退出登录
    logout() {
      this.visible = false
      // 清空本地登录信息
      // localStorage.setItem("user", "");
      // 清空本地登录信息
      localStorage.clear()
      // 清空vuex登录信息
      this.setUser('')
      this.notifySucceed('成功退出登录')
      // this.$router.push("/");
      this.$router.push('/').then(() => {
        location.reload()
      })
    },
    goOrder() {
      this.$emit('linkOtherPage', 'MyOrder', '2')
    },
    goForget() {
      this.forgetPassword = true
    },
    isForgetPassword(val) {
      this.forgetPassword = val
    },
    // 复制：方法一
    copyText() {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = this.userInfo.mobile //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$message.success('复制成功')
    },
  },
}
</script>
<style lang="scss" scoped>
.page-left-box {
  padding: 40px;
}
.back-btn,
.h5-opt-box {
  display: none;
}

.user-tit {
  color: #333333;
  font-size: 24px;
}

.userInfo-box {
  color: #666;
  padding: 40px;
  padding-bottom: 100px;
  background-color: rgba(229, 229, 229, 0.3);
  margin-top: 20px;
  .cur-user-info {
    display: flex;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding-bottom: 50px;
  }
  .user-opt-type {
    padding-top: 30px;
    .el-button {
      border: 1px solid #fa8919;
      color: #fa8919;
    }
  }
}
.user-txt-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-top: 15px;
  color: #333;
  .user-txt-style {
    color: #666;
  }
  p {
    width: 40%;
  }
}
.user-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fa8919;
}
// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .userInfo-box {
    background-color: initial;
    padding-top: 10px;
    padding-left: 20px;

    .user-txt-box {
      p {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: calc(100% - 40px);
        margin-top: 2px;
      }
    }
  }
  .back-btn {
    display: flex;
    align-items: center;
    margin: 20px;
    img {
      width: 30px;
    }
  }
  .user-tit {
    color: #fa8919;
    margin-left: 5px;
    font-size: 20px;
  }
  .user-txt-box {
    display: initial;
  }
  // .user-txt-box p {
  //   width: calc(100% - 40px);
  //   margin-bottom: 10px;
  //   // background-color: red;
  // }
  .h5-opt-box {
    display: block;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
    .el-button {
      border: 1px solid #fa8919;
      color: #fa8919;
    }
  }
}
</style>
<style lang="scss">
.usercenter-box {
  // .el-button--small{
  //   height: 40px;
  //   width: 100px;
  //   font-size: 18px;
  // }
  .box-card {
    box-shadow: none;
    padding: 20px 30px;
    border: none;
  }
  .el-card__header {
    border: none;
  }
}
</style>
